// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useState, useRef, useEffect } from 'react'
import useSWR from 'swr'
import fetch from 'unfetch'

import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import tail from 'lodash/tail'
import map from 'lodash/map'

import moment from 'moment'
import twix from 'twix'

import { compiler } from 'markdown-to-jsx'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticImage } from 'gatsby-plugin-image'

// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'

// import ScrollContainer from 'react-indiana-drag-scroll'

import { graphql } from 'gatsby'

import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import ArrowLeft from '../svg/arrow-left'
import ArrowRight from '../svg/arrow-right'

import Link from '../link'
import '../link/style.less'

import Video from '../video'
import '../video/style.less'

import Audio from '../audio'
import '../audio/style.less'

import BlockParser from '../block-parser'
import '../block-parser/style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import Histograph from './histograph'

import './style.less'

import flattenGatsbyNode from '../../methods/flatten-gatsby-node'
import smallKey from '../../methods/small-key'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

/** [description] */
const fetcher = (url) => fetch(url).then((res) => res.json())

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query MagPageQuery($pathAuto: String) {
    thisPage: allFile(filter: { fields: { pathAuto: { eq: $pathAuto } } }) {
      edges {
        node {
          fields {
            pathAuto
            publishedUnixTimestampAuto
            publishedLongHumandateAuto
            lastModifiedHumandateAuto
            draftAuto
            breadcrumbsAuto {
              title
              slug
            }
          }
          childResources {
            title
            googleSheetId
            contentBlocks {
              type
              content
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** CollectionPage */
const CollectionPage = ({ data: queryResponse, pageContext, ...props }) => {
  const {
    thisPage: {
      edges: [{ node: n }],
    },
    node = flattenGatsbyNode(n),
  } = queryResponse

  const {
    title,
    googleSheetId,
    pathAuto,
    publishedLongHumandateAuto,
    breadcrumbsAuto,
  } = node

  const pageSchema = {
    title,
    slug: pathAuto.substring(1),
    abstract: title,
    breadcrumbs: breadcrumbsAuto,
  }

  const url =
    'https://sheets.googleapis.com/v4/spreadsheets/12J1ECDbZ9pLSi3PnV2qK2EWoNiBWZg9mcRQcxtyfVJ4/values/Sheet1/?alt=json&key=AIzaSyC26-uf-_BtBuSb0lF9ia4D_Z-nJpIU7XE'

  const { data, error } = useSWR(url, fetcher)
  const slides = []

  if (isUndefined(data) === false) {
    const { values } = data
    // const colNames = head(values)
    const rows = tail(tail(values))
    map(rows, (row) => {
      const slide = {}
      map(row, (cell, colNumber) => {
        if (colNumber === 0) {
          slide.number = cell
        }
        if (colNumber === 1) {
          slide.style = cell
        }
        if (colNumber === 2) {
          if (isEmpty(cell) === false) {
            slide.dateStart = new Date(cell).toISOString()
          }
        }
        if (colNumber === 3) {
          if (isEmpty(cell) === false) {
            slide.dateEnd = new Date(cell).toISOString()
          }
        }
        if (colNumber === 4) {
          slide.title = cell
        }
        if (colNumber === 5) {
          slide.text = cell
        }

        if (colNumber === 6) {
          slide.filename = cell
        }

        if (colNumber === 10) {
          slide.url = cell
        }
      })

      let humanReadableDate = 'No date information found'

      if (isUndefined(slide.dateStart) === false) {
        const begins = moment(slide.dateStart, 'YYYY-MM-DD')
        humanReadableDate = begins.format('ddd, MMMM D, YYYY')

        if (isUndefined(slide.dateEnd) === false) {
          const ends = moment(slide.dateEnd, 'YYYY-MM-DD')
          const range = begins.twix(ends, { allDay: false })
          const humanReadableDateRange = range.simpleFormat('ddd, MMMM D')
          const beginsYear = begins.format('YYYY')
          const endsYear = ends.format('YYYY')

          if (beginsYear === endsYear) {
            humanReadableDate = `${humanReadableDateRange}, ${beginsYear}`
          } else {
            humanReadableDate = humanReadableDateRange
          }
        }

        slide.humanReadableDate = humanReadableDate
      }

      slides.push(slide)
    })
  }

  const sliderConf = {
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    prevArrow: <ArrowLeft />,
    nextArrow: <ArrowRight />,
  }

  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  /** [description] */
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  return (
    <StandardPageWrapper
      className="collection-page"
      pageSchema={pageSchema}
      {...props}
    >
      <div className="banner">
        <StaticImage
          className="cover"
          src="https://n1.store.auroville-archives.org/96042/1920w.jpg"
        />
        <div className="titles">
          <h1>Fraternity</h1>
          <p>
            blah blah blah blah blah blah blah blah blah blah blah blah blah
            blah blah blah blah blah blah blah blah blah blah blah blah blah
            blah blah blah blah blah blah blah blah blah blah blah blah blah
            blah blah blah blah blah blah blah blah blah blah blah blah blah
            blah blah blah blah blah blah blah blah blah blah blah blah blah
            blah blah blah blah blah blah blah blah blah blah blah blah blah.
          </p>
        </div>
      </div>
      <Histograph />
      <div className="timeline">
        <h2>Timeline</h2>
        <div className="slider">
          <Carousel {...sliderConf} className="ext-arrow collection-slider">
            {map(slides, (slide) => (
              <Fragment>
                {slide.style === '1' && (
                  <Row
                    justify="space-between"
                    className={`slide style-${slide.style}`}
                  >
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                      <h1>{slide.title}</h1>
                    </Col>
                    <Col xs={24} sm={24} md={20} lg={20} xl={20} xxl={20}>
                      <Video url={slide.url} />
                    </Col>
                  </Row>
                )}
                {slide.style === '2' && (
                  <Row
                    justify="space-between"
                    className={`slide style-${slide.style}`}
                  >
                    <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                      <p className="subtext">{slide.humanReadableDate}</p>
                      <h2>{slide.title}</h2>
                      <p>{slide.text}</p>
                    </Col>
                    <Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
                      <div className="mock">&nbsp;</div>
                    </Col>
                  </Row>
                )}
                {slide.style === '3' && (
                  <Row
                    justify="space-between"
                    className={`slide style-${slide.style}`}
                  >
                    <Col xs={24} sm={24} md={15} lg={15} xl={15} xxl={15}>
                      <div className="mock">&nbsp;</div>
                      <div className="mock">&nbsp;</div>
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
                      <p className="subtext">{slide.humanReadableDate}</p>
                      <h2>{slide.title}</h2>
                      <p>{slide.text}</p>
                    </Col>
                  </Row>
                )}
                {slide.style === '4' && (
                  <div className={`slide style-${slide.style}`}>
                    <div className="primer flow">
                      <h2>{slide.title}</h2>
                      <p>
                        {compiler(slide.text, {
                          wrapper: null,
                          overrides: {
                            a: {
                              component: Link,
                            },
                          },
                        })}
                      </p>
                    </div>
                    <Row justify="space-between">
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <div className="mock">
                          &nbsp;<p className="subtext caption">Caption</p>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <div className="mock">
                          &nbsp;<p className="subtext caption">Caption</p>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <div className="mock">
                          &nbsp;<p className="subtext caption">Caption</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
                {slide.style === '5' && (
                  <Row
                    justify="space-between"
                    className={`slide style-${slide.style}`}
                  >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <h2>{slide.title}</h2>
                      <p>
                        {compiler(slide.text, {
                          wrapper: null,
                          overrides: {
                            a: {
                              component: Link,
                            },
                          },
                        })}
                      </p>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <div className="mock">
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <div
                        className="mock"
                        style={{
                          maxHeight: '62%',
                          marginTop: '19%',
                          marginLeft: '-20%',
                        }}
                      >
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                    </Col>
                  </Row>
                )}
                {slide.style === '6' && (
                  <Row
                    justify="space-between"
                    className={`slide style-${slide.style}`}
                  >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <div className="mock">
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <div
                        className="mock"
                        style={{
                          maxHeight: '62%',
                          marginTop: '19%',
                          marginLeft: '-20%',
                        }}
                      >
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <p>
                        {compiler(slide.text, {
                          wrapper: null,
                          overrides: {
                            a: {
                              component: Link,
                            },
                          },
                        })}
                      </p>
                    </Col>
                  </Row>
                )}
                {slide.style === '7' && (
                  <Row
                    justify="space-between"
                    className={`slide style-${slide.style}`}
                  >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <div className="mock">
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <h2>{slide.title}</h2>
                      <p>
                        {compiler(slide.text, {
                          wrapper: null,
                          overrides: {
                            a: {
                              component: Link,
                            },
                          },
                        })}
                      </p>
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <div
                        className="mock"
                        style={{ height: '49%', marginBottom: '2%' }}
                      >
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                      <div className="mock flow" style={{ height: '49%' }}>
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                    </Col>
                  </Row>
                )}
                {slide.style === '8' && (
                  <Row
                    justify="space-between"
                    className={`slide style-${slide.style}`}
                  >
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                      <h2>{slide.title}</h2>
                      <p>
                        {compiler(slide.text, {
                          wrapper: null,
                          overrides: {
                            a: {
                              component: Link,
                            },
                          },
                        })}
                      </p>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                      style={{ zIndex: 100 }}
                    >
                      <div
                        className="mock"
                        style={{
                          maxHeight: '62%',
                          marginTop: '38%',
                          marginRight: '-30%',
                        }}
                      >
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      xl={8}
                      xxl={8}
                      style={{ zIndex: 90 }}
                    >
                      <div
                        className="mock"
                        style={{
                          maxHeight: '80%',
                          marginTop: '0%',
                          marginLeft: '-20%',
                        }}
                      >
                        &nbsp;<p className="subtext caption">Caption</p>
                      </div>
                    </Col>
                  </Row>
                )}
                {slide.style === '9' && (
                  <div
                    className={`slide style-${slide.style}`}
                    style={{ position: 'relative' }}
                  >
                    <div
                      className="mock"
                      style={{
                        position: 'absolute',
                        zIndex: '90',
                        top: '0%',
                        left: '0%',
                        width: '100%',
                        height: '100%',
                      }}
                    />
                    <Row
                      justify="space-between"
                      style={{
                        position: 'relative',
                        zIndex: '100',
                        padding: '1rem',
                      }}
                    >
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <h2>{slide.title}</h2>
                      </Col>
                      <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                        <p
                          style={{
                            padding: '1rem',
                            background: 'var(--text-dark)',
                            color: 'var(--text-light)',
                          }}
                        >
                          {compiler(slide.text, {
                            wrapper: null,
                            overrides: {
                              a: {
                                component: Link,
                              },
                            },
                          })}
                        </p>
                      </Col>
                    </Row>
                  </div>
                )}
              </Fragment>
            ))}
          </Carousel>
        </div>
      </div>
      <div className="review">
        <Row justify="space-between">
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <ul>
              <li>
                <Link to="/collections/fraternity#h1">Heading #1</Link>
              </li>
              <li>
                <Link to="/collections/fraternity#h2">Heading #2</Link>
              </li>
              <li>
                <Link to="/collections/fraternity#h3">Heading #3</Link>
              </li>
              <li>
                <Link to="/collections/fraternity#h4">Heading #4</Link>
              </li>
              <li>
                <Link to="/collections/fraternity#h5">Heading #5</Link>
              </li>
              <li>
                <Link to="/collections/fraternity#h6">Heading #6</Link>
              </li>
              <li>
                <Link to="/collections/fraternity#h7">Heading #7</Link>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            <h3 id="h1">Heading #1</h3>
            <div className="mock flow constrained">&nbsp;</div>
            <p className="justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h3 id="h2">Heading #2</h3>
            <p className="justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <p className="justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
            <h3 id="h3">Heading #3</h3>
            <p className="justify">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </p>
            <div className="mock-grid mock-grid-c3 mock-grid-r3 flow">
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
            </div>
            <h3 id="h4">Heading #4, PDF Example</h3>
            {/*<Document
              className="flow constrained"
              file="/grimm10.pdf"
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document>*/}
            {/*<p>
              Page {pageNumber} of {numPages}.{' '}
              {pageNumber !== 0 && (
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setPageNumber(pageNumber - 1)
                  }}
                >
                  Previous
                </Link>
              )}
              &nbsp;
              {pageNumber !== numPages && (
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setPageNumber(pageNumber + 1)
                  }}
                >
                  Next
                </Link>
              )}
            </p>*/}
            <p>WIP</p>
            {/*<p>TODO: It maybe flickering in your particular browser.</p>*/}
            <h3 id="h5">Heading #5, Can do video</h3>
            <div className="flow constrained">
              <Video url="https://vimeo.com/channels/earthdayeveryday/647452678" />
            </div>
            <h3 id="h6">Heading #6, And audio...</h3>
            <div className="flow constrained">
              <Audio url="https://soundcloud.com/french79music/03-between-the-buttons?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" />
            </div>
            <h3 id="h7">Heading #7</h3>
            <p className="justify">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo.
            </p>
            <div className="mock-grid mock-grid-c3 mock-grid-r3 flow">
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
              <div className="mock">&nbsp;</div>
            </div>
          </Col>
        </Row>
      </div>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default CollectionPage
