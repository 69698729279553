// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React, { useState, useRef, useEffect } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import random from 'lodash/random'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import * as Plot from '@observablehq/plot'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import addTooltips from './tooltip'

import dataset from '../../data/collections/fraternity.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions

// const { Fragment } = React

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Histograph */
const Histograph = ({ collection, mediaState, ...props }) => {
  const ref = useRef()
  const thisYear = new Date().getFullYear()
  const diff = thisYear - 1968
  const { screenWidth, screenHeight, point } = mediaState
  const twoRem = point * 2
  const twoRemM4 = twoRem - 4
  const leftIndent = point
  const w = screenWidth - twoRemM4
  let h = screenHeight * 0.62

  useEffect(() => {
    Plot.plot2 = ({ tooltip, ...options }) =>
      addTooltips(Plot.plot(options), tooltip, point)

    console.debug(dataset)
    // console.debug(dataset, point)

    const visual = Plot.plot2({
      width: w,
      height: h,
      insetLeft: 10,
      marginLeft: leftIndent,
      x: { domain: [0, diff] },
      y: { domain: [0, 100] },
      // x: { domain: [0, diff], axis: null },
      // y: { domain: [0, 100], axis: null },
      marks: [
        Plot.dot(
          dataset,
          Plot.dodgeY({
            x: 'x',
            y: 'y',
            r: 'r',
            title: (d) => 'Title',
            anchor: 'middle',
            // sort: { channel: 'x' },
            fill: 'var(--text-dark)',
            padding: 1.62,
          })
        ),
      ],
      style: {
        background: 'transparent',
      },
    })
    ref.current.append(visual)
    return () => visual.remove()
  }, [dataset, leftIndent, screenWidth, screenHeight, point])

  return (
    <div className="histograph">
      <h2>History of...</h2>
      <div ref={ref} />
    </div>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    mediaState: state.mediaState,
  })
  // (dispatch) => ({

  // })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedHistograph = compose(
  withState // Add state
)(Histograph)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedHistograph
